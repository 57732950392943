/*
Copyright 2022 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import logo from "./images/wknd-logo-dk.svg";
import Home from "./components/Home";
import AdventureDetail from "./components/AdventureDetail";
import routeConvert from "./utils/routeUtil"; 
import "./App.scss";

const { 
  REACT_APP_PUBLIC_URI
 } = process.env;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <header>
        <Link to={"/"}>
          <img src={REACT_APP_PUBLIC_URI + '/' +  logo} className="logo" alt="WKND Logo"/>
        </Link>        
        <hr />
        </header>
        <Routes>
          <Route path={routeConvert("/:lang?/adventure/:slug")} element={<AdventureDetail/>}/>
          <Route path={routeConvert("/:lang?/")} element={<Home/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
