import { PathUtils } from "@adobe/aem-spa-page-model-manager"

const CONTENT_PATH = "/content/aem-spa-editor/us";
const PAGE_EXTENSION = ".html";
const HOMEPAGE = "/home";
const LANG_PLACEHOLDER = "/:lang?";

function getCurrentPath() {
    return PathUtils.isBrowser() ? window.location.pathname : "";
}

function checkExtendedPath(currentPath) {
    return currentPath && currentPath.startsWith(CONTENT_PATH+"/");
}

export default function routeConvert(relativePath) {
    let path = relativePath;
    const currentPath = getCurrentPath();
    //console.log("RELPATH : " + relativePath + " - current path: " + currentPath);
    if (currentPath) {
        if (checkExtendedPath(currentPath)) {
            path = path.replace(LANG_PLACEHOLDER, "");
            path = LANG_PLACEHOLDER  + HOMEPAGE + (path === "/" ? "" : path);
            path = CONTENT_PATH + path + PAGE_EXTENSION;
        }
    }
    //console.log("OUTCOME PATH: " + path);
    return path;
}

export function routeToAEM(lang, relativePath) {
    return CONTENT_PATH + "/" + (lang ? lang : "en") + relativePath;
}

export function linkConverter(lang, relativePath) {
    //cnsole.log("Lang... " + lang);    
    //console.log("REL... " + relativePath);    
    return routeConvert((lang ? "/"+lang : "") + relativePath);
}