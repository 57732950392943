/* eslint-disable no-unused-vars */
/*
Copyright 2022 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/
import React, {useState} from 'react';
import Adventures from './Adventures';
import { useParams } from "react-router-dom";
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { routeToAEM } from "../utils/routeUtil"; 
import EditableTitle from './editable/EditableTitle';
// The following need to be imported, so that MapTo is run for the components
import EditableText from './editable/EditableText';
import EditableImage from './editable/EditableImage';

/***
 * Displays a grid of current adventures
 */
 function Home() {
    const { lang } = useParams();
    const [adventureActivity, setAdventureActivity] = useState('');

    return (
      <div className="Home">
        
        <ResponsiveGrid
                pagePath={routeToAEM(lang, "/home")}
                itemPath='root/responsivegrid'/>

        <EditableTitle
            pagePath={routeToAEM(lang, "/home")}
            itemPath='root/title'/>

        <div className="adventure-nav">
          <button onClick={() => setAdventureActivity('')}>All</button>
          <button onClick={() => setAdventureActivity('Camping')}>Camping</button>
          <button onClick={() => setAdventureActivity('Cycling')}>Cycling</button>
          <button onClick={() => setAdventureActivity('Rock Climbing')}>Rock Climbing</button>
          <button onClick={() => setAdventureActivity('Skiing')}>Skiing</button>
          <button onClick={() => setAdventureActivity('Social')}>Social</button>
          <button onClick={() => setAdventureActivity('Surfing')}>Surfing</button>
        </div>
        <Adventures adventureActivity={adventureActivity} />
    </div>
    );
}

export default Home;